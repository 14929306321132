import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql, navigate } from 'gatsby'

import SEO from '../components/seo'
import Nav from '../components/nav'
import Footer from '../components/footer'
import Typography from '../components/typography'
import Box from '../components/box'
import Button from '../components/button'
import useHotjarScript from '../utils/hooks/useHotjarScript'

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      background404: file(relativePath: { eq: "404.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  useHotjarScript()

  return (
    <>
      <Nav />
      <SEO title="404 - Not found" />
      <BackgroundImage
        fluid={data.background404.childImageSharp.fluid}
        backgroundColor="black"
        style={{
          height: '90vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <Box style={{ width: 'fit-content' }}>
          <h1 style={{ color: '#ffffff' }}>Something has gone wrong</h1>
          <span
            style={{
              display: 'block',
              borderBottom: '1px solid #ffffff',
              width: '100%',
            }}
          />
        </Box>
        <h3
          style={{
            color: '#ffffff',
            textTransform: 'uppercase',
            fontStyle: 'italic',
          }}
        >
          404 Error found
        </h3>
        <Typography component="p" colorType="colors.white">
          We're sorry, but the page you're looking either doesn't exist or has
          been deleted.
        </Typography>
        <Box marginTop=".5rem" />
        <Button onClick={() => navigate('/')}>bring me back</Button>
      </BackgroundImage>
      <Footer />
    </>
  )
}

export default NotFoundPage
