const config = {
  siteTitle: 'Pulse', // Site title.
  siteTitleShort: 'Pulse', // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: 'Pulse', // Alternative site title for SEO.
  siteLogo: '/logos/logo-1024.png', // Logo used for SEO and manifest.
  siteUrl: 'https://pulseasync.com', // Domain of your website without pathPrefix.
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  siteDescription:
    'Pulse helps remote teams centralize important communications and stay aligned through long-form asynchronous updates around project, processes, decisions, strategy and more.', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml', // Path to the RSS file.
  siteRssTitle: 'Pulse RSS feed', // Title of the RSS feed
  siteFBAppID: '????', // FB Application ID for using app insights
  googleTagManagerId: 'GTM-MB7LXDZ', // GTM tracking ID.
  disqusShortname: 'pulse-blog-1', // Disqus shortname.
  dateFromFormat: 'YYYY-MM-DD', // Date format used in the frontmatter.
  dateFormat: 'DD/MM/YYYY', // Date format for display.
  postsPerPage: 5, // Amount of posts displayed per listing page.
  userName: 'Pulse Team', // Username to display in the author segment.
  userEmail: 'team@pulse.so', // Email used for RSS feed's author segment
  userTwitter: 'pulseasync', // Optionally renders "Follow Me" in the UserInfo segment.
  userLocation: 'San francisco, CA', // User location to display in the author segment.
  copyright: 'Copyright © 2021. Sametab Inc.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#c62828', // Used for setting manifest and progress theme colors.
  backgroundColor: '#e0e0e0', // Used for setting manifest background color.
  blogPrefix: 'blog', // NO TRAILING SLASHES!
  operatorsPrefix: 'operators', // NO TRAILING SLASHES!
  authorsPrefix: 'authors', // NO TRAILING SLASHES!
  changelogPrefix: 'changelog',
  integrationsPrefix: 'integrations',
  // Filter tags for the /integration page
  integrationTags: [
    {
      slug: 'data',
      label: 'Data',
    },
    {
      slug: 'productivity',
      label: 'Productivity',
    },
    {
      slug: 'hris',
      label: 'HRIS',
    },
  ],
}

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === '/') {
  config.pathPrefix = ''
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, '')}`
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === '/')
  config.siteUrl = config.siteUrl.slice(0, -1)

// Make sure siteRss has a starting forward slash
if (config.siteRss && config.siteRss[0] !== '/')
  config.siteRss = `/${config.siteRss}`

module.exports = config
